import Home from '@/components/screens/home/Home';
import { NextPage, GetStaticProps } from 'next';
import { API_URL, REVALIDATE_TIME } from '@/helpers/variables';
import { IBookPage } from '@/interfaces/booksPage.interface';

interface IHomePage {
  popularBooks: IBookPage;
  newBooks: IBookPage;
}

const HomePage: NextPage<IHomePage> = ({ popularBooks, newBooks }) => {
  return <Home popularBooks={popularBooks} newBooks={newBooks} />;
};

export const getStaticProps: GetStaticProps = async () => {
  const [popularRes, newRes] = await Promise.all([fetch(`${API_URL}satellite/books/popular?page=1&page_size=5`), fetch(`${API_URL}satellite/books/news?page=1&page_size=10`)]);
  const [popularBooks, newBooks] = await Promise.all([popularRes.json(), newRes.json()]);

  return { props: { popularBooks, newBooks }, revalidate: REVALIDATE_TIME };
};

export default HomePage;
