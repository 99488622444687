import { FC } from 'react';
import BookCardItem from '../book-card-item/BookCardItem';
import styles from './BookCardsList.module.scss';
import { IBooksPageData } from '@/interfaces/booksPage.interface';

interface IBookCardsListProps {
  books: IBooksPageData[];
  extraClassName?: string;
}

const BookCardsList: FC<IBookCardsListProps> = ({ books, extraClassName }) => {
  const getListClassNames = () => (extraClassName ? `${styles.bookCardsList} ${styles[`${extraClassName}`]}` : `${styles.bookCardsList}`);

  return (
    <div className={getListClassNames()}>
      {books.length ? (
        books.map((book: IBooksPageData) => {
          // const createdAt = !!createdDate ? book.created_at : "";
          return <BookCardItem createdAt={book.created_at} id={book.id} rating={book.rating} key={book.id} author={book.author} book={book.book} hideLitresData={book.hide_litres_data} />;
        })
      ) : (
        <div>Книги не найдены</div>
      )}
    </div>
  );
};

export default BookCardsList;
