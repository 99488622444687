import { FC, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { getAuthorFullName } from '@/helpers/helpers';
import { getTruncatedTxt } from '@/helpers/helpers';
import { IBookPage } from '@/interfaces/booksPage.interface';
import { DEV_ABSOLUTE_URL } from '@/helpers/variables';
import styles from './TopSearchWidgets.module.scss';

interface ITopSearchWidgetsProps {
  popularBooks: IBookPage;
}

const TopSearchWidgets: FC<ITopSearchWidgetsProps> = ({ popularBooks }) => {
  const router = useRouter();

  const [inputSearch, setInputSearch] = useState('');

  const getSearchWord = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(event.target.value);
  };

  const searchByPressEnter = (event: React.KeyboardEvent<object>) => {
    if (String(event.code) == 'Enter') {
      router.push(`/search/book/${searchWord}`);
    }
  };

  const searchWord = inputSearch.includes(' ') ? inputSearch.split(' ').join('+') : inputSearch;

  return (
    <div className={styles.b_top_idx}>
      <div className={styles.b_various_links}>
        <h2 className={styles.b_top_title}>Библиотека</h2>
        <div className={styles.t_search}>
          <div className={styles.search_form_main}>
            <input value={inputSearch} onChange={getSearchWord} onKeyDown={searchByPressEnter} type="text" name="query" className={styles.input} placeholder="Поиск в библиотеке..." />
            <div className={styles.submit}>{!!inputSearch.length ? <Link prefetch={false} className={styles.button} href={`/search/book/${searchWord}`}></Link> : <span className={styles.button}></span>}</div>
          </div>
        </div>

        <div className={styles.row_link}>
          <span className={styles.name}>
            <Link prefetch={false} href="/lib">
              Книги
            </Link>
          </span>
          <span className={styles.desc}>Большая, бесплатная библиотека интересных книг, качай и читай.</span>
        </div>

        <div className={styles.row_link}>
          <span className={styles.name}>
            <Link prefetch={false} href="/lib/type/audio">
              Аудиокниги
            </Link>
          </span>
          <span className={styles.desc}>Надоело читать книги с экрана? Загрузи и слушай в любом месте.</span>
        </div>
      </div>
      <div className={styles.b_top_5}>
        <div className={styles.title}>ТОП 5 недели</div>
        <ol className={styles.book_names}>
          {popularBooks.data.map((book) => (
            <li key={book.id}>
              <Link prefetch={false} href={`/lib/id${book.id}`}>
                {getTruncatedTxt(`${getAuthorFullName(book.author.first_name, book.author.middle_name, book.author.last_name)} - ${book.book.title}`, 27)}
              </Link>
            </li>
          ))}
        </ol>
        <div className={styles.t_book_popular}>Рейтинг основан на популярности</div>
      </div>
      <div className={styles.b_recommend}>
        <div className={styles.title}>Рекомендуем</div>
        <div className={styles.book}>
          <Link prefetch={false} href="#">
            <span className={styles.img}>
              <img width="120" height="172" src={`${DEV_ABSOLUTE_URL}/covers_120/68960253.jpg`} alt="cover" />
            </span>
            <span className={styles.name}>
              Александр Арсентьев
              <br />
              <br />
              АлкоМаг 2
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopSearchWidgets;
