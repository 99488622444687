import { FC } from 'react';
import styles from './SectionSubTitle.module.scss';

interface ISectionTitleProps {
  name: string;
  extraClassName?: string;
}

const SectionSubtitle: FC<ISectionTitleProps> = ({ name, extraClassName }) => {
  const getHeadingClassNames = () => (extraClassName ? `${styles.title} ${styles[`${extraClassName}`]}` : styles.title);

  return <h2 className={getHeadingClassNames()}>{name}</h2>;
};

export default SectionSubtitle;
